import React, { ReactNode } from "react";

import {
  TransparentButton,
  Text,
  LegacyText,
  SystemProps,
  Flex,
  Box,
  Divider,
  Link,
} from "flicket-ui";

import { ExtendedFile } from "~graphql/sdk";
import { Modal } from "./common.Modal";
import { Icon } from "./Icon";
import { SlateRenderer } from "./Slate";
import Image from "~components/common/Image";

interface ContentModalProps extends SystemProps {
  isOpen: boolean;
  setIsOpen: (boolean) => void;
  title?: string;
  richText?: string;
  image?: ExtendedFile;
  subtitle?: string;
  location?: string;
  date?: string;
  coordinates?: string;
  children?: ReactNode;
}

export const ContentModal = ({
  isOpen,
  setIsOpen,
  title,
  richText,
  image,
  subtitle,
  location,
  date,
  coordinates,
  children,
  ...props
}: ContentModalProps) => {
  return (
    <Modal
      maxWidth={{ _: "100%", md: "720px" }}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      {...props}
    >
      <Flex
        flexDir="column"
        bg={{ _: "transparent", md: "white" }}
        pt={{ _: 0, md: 4 }}
        pb={{ _: 0, md: 2 }}
        px={{ _: 0, md: 4 }}
        borderRadius="lg"
      >
        <Flex
          w="100%"
          bg="white"
          px={{ _: 2, md: 0 }}
          pb={{ _: 3, md: 2 }}
          pt={{ _: 3, md: 0 }}
          alignItems="center"
          borderBottom="1px"
          borderBottomColor="N200"
          justifyContent={{ _: "flex-start", md: "space-between" }}
        >
          <TransparentButton
            d={{ _: "flex", md: "none" }}
            onClick={() => setIsOpen(false)}
            mr={2}
          >
            <Icon icon="chevron-left" fontSize={6} color="N800" />
          </TransparentButton>
          <Box>
            <LegacyText
              lineHeight="normal"
              fontSize={{ _: 6, md: 4 }}
              fontWeight="extraBold"
              color="N800"
            >
              {title}
            </LegacyText>
            <LegacyText
              fontSize={2}
              lineHeight="medium"
              fontWeight={{ _: "demiBold", md: "heavy" }}
              d="flex"
              color={{ _: "N700", md: "S300" }}
            >
              {subtitle}
            </LegacyText>
          </Box>
          <TransparentButton
            d={{ _: "none", md: "flex" }}
            onClick={() => setIsOpen(false)}
          >
            <Icon icon="close" fontSize={6} color="N800" />
          </TransparentButton>
        </Flex>

        {image && <Image image={image} alt={title} mt={3} />}

        <Box
          px={{ _: 3, md: 0 }}
          pb={2}
          pt={{ _: 3, md: 0 }}
          mx={{ _: 2, md: 0 }}
          mt={3}
          w={{ _: "calc(100% - 32px)", md: "100%" }}
          borderRadius={{ _: "sm", md: "none" }}
          bg={{ _: "white", md: "transparent" }}
        >
          {!!richText && <SlateRenderer value={richText} />}
          {(location || date) && (
            <>
              <Divider pt={1} />
              <Flex
                flexDir={{ _: "column", md: "row" }}
                alignItems={{ _: "flex-start", md: "center" }}
                justifyContent="flex-start"
                pt={2}
              >
                {date && (
                  <Flex mr={{ _: 0, md: 5 }}>
                    <Icon icon="date-range" fontSize={6} color="N800" />
                    <LegacyText fontWeight="extraBold" fontSize={4} ml={1}>
                      {date}
                    </LegacyText>
                  </Flex>
                )}
                {location && (
                  <Link
                    display="flex"
                    target="_blank"
                    href={`https://www.google.com/maps/search/?api=1&query=${coordinates}`}
                    mt={{ _: 1, md: 0 }}
                  >
                    <Icon icon="map" fontSize={6} color="N800" />
                    <LegacyText fontWeight="extraBold" fontSize={4} ml={1}>
                      {location}
                    </LegacyText>
                  </Link>
                )}
              </Flex>
            </>
          )}
          {children}
        </Box>
      </Flex>
    </Modal>
  );
};
