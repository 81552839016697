import React from "react";
import { Text, LegacyText, Flex } from "flicket-ui";
import { startCase } from "lodash";

export const Tag = ({ status, height = 24, dot = 16, ...props }) => {
  const STATUS_COLORS = {
    Completed: "success",
    PartPaid: "success50",
    Paid: "success",
    Success: "success",
    Waiting: "warning",
    Pending: "warning",
    Unknown: "warning",
    Transferred: "warning",
    Hold: "warning50",
    Expired: "N300",
    Error: "error",
    Failure: "error",
    Canceled: "error",
  };

  return (
    <Flex
      display="inline-flex !important"
      borderRadius="full"
      bg="N200"
      p="1/2"
      pr={1}
      alignItems="center"
      height={height}
      color="N700"
      fontSize={1}
      {...props}
    >
      <Flex
        borderRadius="full"
        width={dot}
        height={dot}
        bg={STATUS_COLORS[status]}
        mr="1/2"
      />
      <LegacyText fontWeight="extraBold">{startCase(status)}</LegacyText>
    </Flex>
  );
};
