import { useRouter } from "next/router";
import { useOrganization } from "~hooks/useOrganization";
import { setState } from "~features/seated-reservation/store";
import { CreateOrderInput, GetOrderJobStatusQuery } from "~graphql/sdk";
import { useReCaptchaSDK } from "~hooks/useReCaptcha";
import { handlePromise, sdk } from "~lib";
import { getGraphQLError, showToast, trackCheckout } from "~lib/helpers";
import {
  buildCustomErrorMessage,
  buildCustomErrorTitle,
} from "./createOrderErrorHelpers";
import { seekaTrackCheckout } from "~lib/seeka";
import { useSeekaConverge } from "@seeka-labs/converge-react";

const MAX_ORDER_CREATION_TIME_MS = 1000 * 60 * 100; // 100 minutes
const ORDER_CREATION_POLLING_INTERVAL_MS = 1000 * 2; // 2 seconds

export const useCreateOrder = () => {
  const router = useRouter();
  const { organization } = useOrganization();
  const { recaptchaSdkGenerator } = useReCaptchaSDK();
  const converge = useSeekaConverge();

  const createOrder = async (
    sdkFn: typeof sdk,
    orgId: string,
    input: CreateOrderInput
  ) => handlePromise(async () => sdkFn({ orgId }).createOrder({ input }));

  const getOrderJobStatus = async (
    sdkFn: typeof sdk,
    orgId: string,
    jobId: string
  ) => handlePromise(async () => sdkFn({ orgId }).getOrderJobStatus({ jobId }));

  const createOrderAndWaitForCompletion = async (
    input: CreateOrderInput,
    onError?: (
      errorMessage: string,
      errorTitle?: string
    ) => Promise<void> | void,
    onSuccess?: (order: GetOrderJobStatusQuery) => Promise<void> | void
  ) => {
    const cartRecovery = router.query.cartRecovery === "true";
    const {
      error: createError,
      data: createOrderResponse,
    } = await handlePromise(
      async () =>
        await createOrder(
          await recaptchaSdkGenerator("createOrder"),
          organization?.id,
          {
            ...input,
            ...(router.query.customer && {
              userId: router.query.customer as string,
            }),
            ...(router.query.posId && {
              posId: router.query.posId as string,
            }),
            ...(cartRecovery && {
              isCreatedFromRecoveredCart: cartRecovery,
            }),
          }
        )
    );
    let order = createOrderResponse?.data?.createOrder?.order;
    const jobId = createOrderResponse?.data?.createOrder?.jobId;
    let customError = createOrderResponse?.data?.createOrder?.createOrderError;
    let graphQlError = createOrderResponse?.error || createError;
    let clientError: Error = undefined;
    if (!order && !graphQlError && !customError && jobId) {
      const startTime = new Date().getTime();
      do {
        const {
          error: getOrderJobStatusError,
          data: getOrderJobStatusResponse,
        } = await handlePromise(
          async () =>
            await getOrderJobStatus(
              await recaptchaSdkGenerator("getOrderJobStatus"),
              organization?.id,
              jobId
            )
        );
        graphQlError =
          getOrderJobStatusResponse?.error || getOrderJobStatusError;
        order = getOrderJobStatusResponse?.data?.getOrderJobStatus?.order;
        customError =
          getOrderJobStatusResponse?.data?.getOrderJobStatus?.createOrderError;

        if (new Date().getTime() > startTime + MAX_ORDER_CREATION_TIME_MS) {
          clientError = new Error(
            "There was an issue with your network. Please reconnect and try again."
          );
        }
        await new Promise((resolve) =>
          setTimeout(resolve, ORDER_CREATION_POLLING_INTERVAL_MS)
        );
      } while (!order && !graphQlError && !clientError && !customError);
    }

    if (!order && !graphQlError && !customError && !clientError) {
      clientError = new Error("Order creation failed. Please try again.");
    }

    const errorMessage = (() => {
      if (graphQlError) {
        return getGraphQLError(graphQlError)?.message;
      }
      if (customError) {
        return buildCustomErrorMessage(customError);
      }
      return clientError?.message;
    })();

    let errorTitle: string | undefined = undefined;

    if (customError) {
      errorTitle = buildCustomErrorTitle(customError);
    }

    const errorCode = graphQlError && getGraphQLError(graphQlError)?.statusCode;
    // 987 is the error code for unmet purchase restrictions
    if (errorCode === 987) {
      setState({
        unmetPurchaseRestrictionsMessage: errorMessage,
      });
      return false;
    }

    if (errorMessage) {
      if (onError) {
        await onError(errorMessage, errorTitle);
      } else {
        showToast(errorMessage, "error");
      }
      return false;
    }

    trackCheckout(order, {
      currency: organization.currency,
    });

    seekaTrackCheckout(converge, order, organization.currency);

    if (onSuccess) {
      await onSuccess({
        getOrderJobStatus: {
          order: order,
          jobId: jobId,
        },
      });
    }
    sessionStorage.removeItem("accessCodeId");

    await router.push("/checkout/[orderId]", `/checkout/${order.id}`);

    return true;
  };

  return { createOrderAndWaitForCompletion };
};
