import React, { useState } from "react";
import { Box, Flex, Link, SystemProps, Text, LegacyText } from "flicket-ui";
import OutsideClickHandler from "react-outside-click-handler";
import styled from "styled-components";

import { useAccount } from "~hooks";
import { getUrl } from "~lib";
import { Icon } from "~components/common/Icon";
import { CurrentOrganizationQuery } from "~graphql/sdk";

interface DropdownProps extends SystemProps {
  firstName: string;
  lastName: string;
  organization: CurrentOrganizationQuery["currentOrganization"];
}

const DropdownToggle = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  // color: ${(p) => (p.isOpen ? p.theme.colors.white : p.theme.colors.P300)};
  cursor: pointer;
`;

const DropdownContent = styled(Box)`
  display: flex;
  flex-direction: column;

  position: absolute;
  top: 48px;
  right: -8px;

  padding: 8px;

  background-color: ${(p) => p.theme.colors.white};

  border-radius: 12px;

  box-shadow: ${(p) => p.theme.shadows.hover};

  z-index: ${(p) => p.theme.zIndices.dropdown};

  > a,
  > div {
    display: flex;
    align-items: center;

    min-width: 156px;

    margin-top: 4px;
    margin-bottom: 4px;

    padding: 5px 20px 5px 8px;

    border-radius: 12px;

    color: ${(p) => p.theme.colors.N600};
    font-size: ${(p) => p.theme.fontSizes[3]};
    font-weight: ${(p) => p.theme.fontWeights.demiBold};
    letter-spacing: -0.5px;

    svg path {
      stroke: ${(p) => p.theme.colors.N600};
    }

    &:hover {
      background-color: ${(p) => p.theme.colors.P100};

      color: ${(p) => p.theme.colors.P300};

      svg path {
        stroke: ${(p) => p.theme.colors.P300};
      }
    }

    &:nth-of-type(2) {
      svg path {
        fill: ${(p) => p.theme.colors.N600};
        stroke: initial;
      }

      &:hover {
        svg path {
          fill: ${(p) => p.theme.colors.P300};
          stroke: initial;
        }
      }
    }
  }
`;

export const Dropdown = ({
  organization,
  firstName,
  lastName,
  ...props
}: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { logout, isAdmin, isPOS, user } = useAccount();

  const handleOpen = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleLogout = async () => logout();

  return (
    <Box position="relative" {...props}>
      <DropdownToggle isOpen={isOpen} onClick={handleOpen}>
        <Icon icon="user-account" mr="6/4" fontSize={6} />
        <Flex flexDir="column">
          <LegacyText lineHeight="medium" fontWeight="demiBold" color="N800">
            My Account
          </LegacyText>
          {user.points > 0 && (
            <LegacyText fontSize={1}>
              {user.points} {organization.point?.name ?? "Points"}
            </LegacyText>
          )}
        </Flex>
      </DropdownToggle>
      {isOpen && (
        <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
          <DropdownContent>
            <Link to="/account">
              <Icon icon="user-account" mr="6/4" fontSize={6} />
              My Account
            </Link>
            <Link to="/tickets">
              <Icon icon="my-tickets" mr="6/4" fontSize={6} />
              My Tickets
            </Link>
            {isAdmin && (
              <Link href={`${getUrl(organization?.slug)}`}>
                <Icon icon="admin-panel" mr="6/4" fontSize={"22px" as any} />
                Admin panel
              </Link>
            )}
            {isPOS && localStorage.getItem("posId") && (
              <Link
                href={`${getUrl(organization?.slug)}/pos/${localStorage.getItem(
                  "posId"
                )}`}
              >
                <Icon icon="admin-panel" mr="6/4" fontSize={"22px" as any} />
                POS panel
              </Link>
            )}
            <a onClick={handleLogout}>
              <Icon icon="logout" mr="6/4" fontSize={6} />
              Log out
            </a>
          </DropdownContent>
        </OutsideClickHandler>
      )}
    </Box>
  );
};
