import { sdk } from "~lib";
import { handlePromise } from "~lib/helpers";
import { CurrentOrganizationQuery } from "~graphql/sdk";
import { State } from "./helpers/handlePromise";
import { IncomingMessage, ServerResponse } from "http";
import { NextApiResponse } from "next";

export const getServerSideOrganization = async (req: IncomingMessage) => {
  const MAX_REQUEST_DURATION_MS = 45 * 1000; // 45 seconds

  const host = req?.headers
    ? `https://${req.headers["x-forwarded-host"] || req.headers.host}`
    : "";

  const result = Promise.race<State<CurrentOrganizationQuery>>([
    new Promise((_, reject) =>
      setTimeout(
        () => reject(new Error("CURRENT_ORGANIZATION_TIMEOUT")),
        MAX_REQUEST_DURATION_MS
      )
    ),
    handlePromise(async () =>
      sdk({
        host,
        server: {
          req,
        },
      }).currentOrganization()
    ),
  ]);

  return result;
};

export const getServerSideI18n = async (
  req: IncomingMessage,
  orgId: string
) => {
  return handlePromise(async () =>
    sdk({
      orgId,
      server: {
        req,
      },
    }).getI18n()
  );
};

export const getServerSideSeoMetadata = async (
  req: IncomingMessage,
  orgId: string,
  eventId?: string,
  membershipId?: string
) =>
  handlePromise(async () =>
    sdk({ orgId, server: { req } }).seoMetadata({ eventId, membershipId })
  );

export function setCacheHeaders(res?: NextApiResponse | ServerResponse) {
  res?.setHeader(
    "Cache-Control",
    "public, s-maxage=30, stale-while-revalidate=60"
  );
  res?.setHeader("Vary", "Host");
}
