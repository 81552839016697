import { PointItemQuery } from "~graphql/sdk";
import {
  EventQuery,
  Fee,
  FeeType,
  IntegrationType,
  ItemFee,
  MembershipQuery,
  ReferralCampaign,
  ReferralRewardType,
} from "~graphql/typed-document-nodes";

type CartItem = {
  itemId: string;
  price: number;
  quantity: number;
  itemFee: ItemFee;
};

type Total = {
  itemTotal: number;
  fees: number;
  discount: number;
  total: number;
};

type Multibuy =
  | EventQuery["event"]["multiBuyPromotions"][number]
  | MembershipQuery["membership"]["multiBuyPromotions"][number];

export type OrderFee =
  | EventQuery["event"]["fees"]
  | MembershipQuery["membership"]["fees"]
  | PointItemQuery["pointItem"]["fees"];

export class Cart {
  private cart = new Map<string, CartItem>();
  private referralCampaign?: ReferralCampaign | number;

  private changeSeatsCompensation = 0;
  private disableFees = true;
  private pos = false;

  public fees = 0;
  public total = 0;
  public multibuyDiscount = 0;
  public referralDiscount = 0;
  public stripeAfterPay = 0;
  public afterPay = 0;
  public laybuy = 0;
  public multibuyId?: string;
  public organizationTax = 0;
  public smsConfirmationFeeApplied = false;
  public smsConfirmationFee = 0;
  public defaultTransactionFee = 0;
  public defaultTransactionFeeGateway;
  public manualFees = 0;

  public constructor(
    private readonly orderFees: OrderFee,
    private readonly multibuys: Multibuy[] = []
  ) {}

  /**
   * Adds an item (e.g ticket, addon, membership, points).
   */
  public add(itemId: string, price: number, quantity = 1): void {
    if (quantity <= 0) {
      return;
    }

    const itemFee = this.orderFees.items.find((i) => i.id === itemId);

    if (!itemFee) {
      return;
    }

    price = Math.max(0, price);

    // Key by id and price as a ticket can have different
    // pricing depending on what Zone it belongs to
    const key = `${itemId}:${price}`;

    this.cart.set(key, {
      itemId,
      price,
      quantity: (this.cart.get(key)?.quantity ?? 0) + quantity,
      itemFee,
    });

    this.calculateTotal();
  }

  /**
   * Return the fee for the given item.
   */
  public itemFee(itemId: string, price: number): number {
    const itemFee = this.orderFees.items.find((i) => i.id === itemId);

    if (!itemFee) {
      return 0;
    }

    return +this._itemFee(itemFee, price);
  }

  public setReferralCampaign(referralCampaign: ReferralCampaign | number) {
    this.referralCampaign = referralCampaign;
    this.calculateTotal();
  }

  public setChangeSeatsCompensation(amount: number) {
    this.changeSeatsCompensation = Math.max(0, amount);
    this.calculateTotal();
  }

  /**
   * Include fees in the grand total.
   */
  public includeFees(include: boolean) {
    this.disableFees = !include;
    this.calculateTotal();
  }

  public isPos(enabled: boolean) {
    this.pos = enabled;
    this.calculateTotal();
  }

  public sendSmsConfirmation(send: boolean) {
    this.smsConfirmationFeeApplied = send;
    this.calculateTotal();
  }

  public addFee(fee: number) {
    this.manualFees += fee;
    this.calculateTotal();
  }

  /**
   * Grand total including all fees.
   */
  private calculateTotal(): void {
    const itemTotal = this._itemTotal();

    if (itemTotal.total <= 0) {
      return;
    }

    let discount = itemTotal.discount;
    let fees = itemTotal.fees;

    if (typeof this.referralCampaign === "number") {
      discount += this.referralCampaign;
    } else if (
      this.referralCampaign?.referralUserRewardType ===
      ReferralRewardType.Discount
    ) {
      this.referralDiscount = this.calcFee(
        itemTotal.itemTotal,
        this.referralCampaign.referralUserRewardQuantityType,
        this.referralCampaign.referralUserRewardQuantity
      );
      discount += this.referralDiscount;
    }

    // if (this.changeSeatsCompensation > 0) {

    // }

    // Order fees
    const defaultGateway = this.pos
      ? undefined
      : this.orderFees.gateways.find((g) => g.isDefault);
    this.defaultTransactionFee = 0;
    this.smsConfirmationFee = 0;
    let subTotal = itemTotal.total + this.manualFees;
    fees += this.manualFees;

    for (const fee of this.orderFees.fees) {
      if (this.skipFee(fee)) {
        continue;
      }

      const orderFee = this.calcFee(subTotal, fee.type, fee.value);

      if (defaultGateway && defaultGateway.id === fee.id) {
        this.defaultTransactionFee += +orderFee;
        this.defaultTransactionFeeGateway = defaultGateway;
        continue;
      }

      if (fee.name.includes("SMS")) {
        this.smsConfirmationFee = fee.value;

        if (!this.smsConfirmationFeeApplied || this.pos) {
          continue;
        }
      }

      subTotal += orderFee;
      fees += orderFee;
    }

    if (this.orderFees.organizationExclusiveTaxRate > 0) {
      this.organizationTax = this.calcFee(
        itemTotal.itemTotal - discount,
        FeeType.Percentage,
        this.orderFees.organizationExclusiveTaxRate
      );
    }

    this.total = itemTotal.itemTotal - discount + fees;

    this.calculateStripeAfterpay(this.total);
    this.calculateBnpl(this.total);

    this.fees = +(fees + this.defaultTransactionFee);

    if (!this.disableFees) {
      this.total += this.organizationTax;
    }

    if (this.total > 0 && !this.pos) {
      this.total += this.defaultTransactionFee;
    }

    this.total = +this.total.toFixed(2);
  }

  /**
   * Subtotal of items excluding any order fees.
   */
  private _itemTotal(): Total {
    let itemTotal = 0;
    let discount = 0;
    let fees = 0;

    const multibuy = this.getMultibuy();

    if (multibuy) {
      this.multibuyId = multibuy.id;
    }

    for (const cart of this.cart.values()) {
      if (cart.itemFee.isComp) {
        continue;
      }

      let multibuyUses =
        multibuy?.itemId === cart.itemId ? multibuy.giftedQty : 0;

      for (let i = 0; i < cart.quantity; i++) {
        let price = cart.price;

        itemTotal += price;

        if (multibuyUses) {
          price = Math.min(price, multibuy.price);
          discount += price <= 0 ? cart.price : cart.price - price;

          multibuyUses--;
        }

        fees += this._itemFee(cart.itemFee, price);
      }
    }

    if (multibuy && discount > 0) {
      this.multibuyDiscount = discount;
    }

    itemTotal = +itemTotal;
    discount = +discount;
    fees = +fees;

    return {
      itemTotal,
      discount,
      fees,
      total: +(itemTotal - discount + fees),
    };
  }

  /**
   * Return the sum of fees for the given item.
   */
  private _itemFee(itemFee: ItemFee, price: number): number {
    let fees = 0;

    for (const fee of itemFee.fees) {
      if (this.skipFee(fee) || itemFee.isComp) {
        continue;
      }

      fees += this.calcFee(price, fee.type, fee.value);
    }

    return fees;
  }

  private calcFee(price: number, feeType: string, value: number): number {
    if (feeType.toLowerCase() === "percentage") {
      return +((value / 100) * price).toFixed(2);
    }

    return value;
  }

  private skipFee(fee: Fee): boolean {
    return this.disableFees || fee.isOptional;
  }

  /**
   * Total to pay for Stripe Afterpay.
   */
  private calculateStripeAfterpay(total: number) {
    this.stripeAfterPay = 0;

    if (total <= 0) {
      return;
    }

    for (const gateway of this.orderFees.gateways) {
      if (
        gateway.type === IntegrationType.PaymentStripe &&
        gateway.bnplEnabled
      ) {
        let transactionType = gateway.transactionFeeType2;
        let transactionFee = gateway.transactionFee2;

        if (gateway.bnplTransactionFeePercent != null) {
          transactionType = FeeType.Percentage;
          transactionFee = gateway.bnplTransactionFeePercent;
        }

        if (this.disableFees) {
          this.stripeAfterPay = +total;
          return;
        }

        const fee = this.calcFee(total, transactionType, transactionFee);

        this.stripeAfterPay = +(
          total +
          fee +
          gateway.transactionFee +
          this.organizationTax
        );
      }
    }
  }

  /**
   * Total to pay for BNPL gateways.
   */
  private calculateBnpl(total: number) {
    this.afterPay = 0;
    this.laybuy = 0;

    if (total <= 0) {
      return;
    }

    for (const gateway of this.orderFees.gateways) {
      let fee = this.calcFee(
        total,
        gateway.transactionFeeType,
        gateway.transactionFee
      );

      fee = this.disableFees ? total : +(total + fee + this.organizationTax);

      if (gateway.type === IntegrationType.PaymentAfterpay) {
        this.afterPay = fee;
      }

      if (gateway.type === IntegrationType.PaymentLaybuy) {
        this.laybuy = fee;
      }
    }
  }

  private getMultibuy() {
    if (!this.multibuys.length) {
      return;
    }

    for (const multibuy of this.multibuys) {
      if (!multibuy.enabled) {
        continue;
      }

      const cart = [...this.cart.values()];

      const getType =
        ("getTicketType" in multibuy && multibuy.getTicketType) ||
        ("getMembershipType" in multibuy && multibuy.getMembershipType);

      const buyType =
        ("buyTicketType" in multibuy && multibuy.buyTicketType) ||
        ("buyMembershipType" in multibuy && multibuy.buyMembershipType);

      const getTickets = cart.find((c) => c.itemId === getType.id);
      const buyTickets = cart.find((c) => c.itemId === buyType.id);

      if (!getTickets || !buyTickets) {
        continue;
      }

      const buySeatQty = Math.floor(buyTickets.quantity / multibuy.buyQuantity);

      const giftedQty =
        buySeatQty * multibuy.getQuantity <= getTickets?.quantity
          ? buySeatQty * multibuy.getQuantity
          : getTickets?.quantity;

      if (giftedQty <= 0) {
        continue;
      }

      return {
        id: multibuy.id,
        itemId: getType.id,
        giftedQty,
        price: multibuy.price,
      };
    }
  }
}
