import React, { ReactNode } from "react";
import { Box, Flex, Link, Text, LegacyText, system, SystemProps } from "flicket-ui";
import styled from "styled-components";

interface CardProps extends SystemProps {
  image: any;
  tagTitle?: string;
  tagSubtitle?: string;
  linkProps?: {
    children?: ReactNode;
    href?: string;
    linkAs?: string;
    to?: string;
    noHoverEffect?: boolean;
  };
  children: ReactNode;
}

const StyledCard = styled(Flex)`
  flex-flow: column nowrap;
  position: relative;
  width: 100%;

  background-color: ${(p) => p.theme.colors.white};

  color: ${(p) => p.theme.colors.N800};

  font-size: ${(p) => p.theme.fontSizes[2]};
  letter-spacing: -0.165px;

  border-radius: 12px;

  overflow: hidden;
  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: ${(p) => p.theme.shadows.hover};
  }

  > a {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  ${system}
`;

const CardImage = styled.div`
  position: relative;
  height: 240px;
  width: 100%;
  background-color: ${(p) => p.theme.colors.N200};

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  border-radius: 12px 12px 0 0;
`;

const CardInfo = styled(Box)`
  flex: 1;
  margin-top: -1px;
  padding: 16px;

  border: 1px solid ${(p) => p.theme.colors.N200};
  border-radius: 0 0 12px 12px;
`;

const CardTag = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;

  padding: 8px;

  background-color: ${(p) => p.theme.colors.S300};

  color: ${(p) => p.theme.colors.white};

  border-radius: 12px 0 12px 0;
`;

export const Card = ({
  image,
  children,
  tagTitle,
  tagSubtitle,
  linkProps,
  ...props
}: CardProps) => {
  return (
    <StyledCard {...props}>
      {linkProps && <Link {...linkProps} />}
      {image !== "/static/no-image.jpg" && (
        <CardImage style={{ backgroundImage: `url(${image})` }}>
          {tagTitle && (
            <CardTag>
              <LegacyText
                fontSize={2}
                lineHeight="medium"
                fontWeight="heavy"
                variant="uppercase"
              >
                {tagTitle}
              </LegacyText>
              <LegacyText fontSize={2} lineHeight="medium" fontWeight="demiBold">
                {tagSubtitle}
              </LegacyText>
            </CardTag>
          )}
        </CardImage>
      )}
      <CardInfo>{children}</CardInfo>
    </StyledCard>
  );
};
