import {
  OrderQuery,
  FeeType,
  OrderChannel,
  IntegrationType,
} from "~graphql/sdk";
import { getPaymentCount } from "./getPaymentCount";
import { getTransactionFee as getTransactionFee2 } from "./getTransactionFee";
import { usePaymentOptionsStore } from "./paymentOptions.store";
import { Cart } from "../cart";

export type OrderGateway =
  | OrderQuery["order"]["membership"]["gateways"][number]
  | OrderQuery["order"]["event"]["gateways"][number];

export type Method = OrderGateway["id"] | "point";
interface GetTransactionFeeAmountProps {
  total: number;
  gateway: OrderGateway;
  paymentCount: number;
}

export type TransactionFee = {
  transactionFeeAmount: number | null;
  transactionFeeType?: FeeType;
  transactionFee?: number;
  transactionFeeType2?: FeeType;
  transactionFee2?: number;
};

const getTransactionFeeAmount = ({
  total,
  gateway,
  paymentCount,
}: GetTransactionFeeAmountProps): TransactionFee => {
  let transactionFeeAmount = 0;

  if (!gateway) return { transactionFeeAmount };

  if (!gateway.transactionFeeType || gateway.transactionFee === undefined) {
    transactionFeeAmount = null;
  }

  if (gateway) {
    if (
      (gateway.type === IntegrationType.PaymentStripe ||
        gateway.type === IntegrationType.PaymentPin) &&
      gateway.transactionFee2 &&
      gateway.transactionFeeType2
    ) {
      // if transactionFeeType is percentage, then transactionFeeType2 should be flat rate.
      if (gateway.transactionFeeType === FeeType.Percentage) {
        transactionFeeAmount = +(
          (total / 100) * gateway.transactionFee +
          gateway.transactionFee2 * paymentCount
        ).toFixed(2);
      } else if (gateway.transactionFeeType === FeeType.FlatRate) {
        transactionFeeAmount = +(
          (total / 100) * gateway.transactionFee2 +
          gateway.transactionFee * paymentCount
        ).toFixed(2);
      }
    } else {
      if (gateway.transactionFeeType === FeeType.Percentage) {
        transactionFeeAmount = +(
          (total / 100) *
          gateway.transactionFee *
          paymentCount
        ).toFixed(2);
      } else if (gateway.transactionFeeType === FeeType.FlatRate) {
        transactionFeeAmount = +(gateway.transactionFee * paymentCount).toFixed(
          2
        );
      }
    }
  }

  // transactionFeeAmount is a derived value
  return {
    transactionFeeAmount,
    transactionFeeType: gateway.transactionFeeType,
    transactionFee: gateway.transactionFee,
    transactionFee2: gateway.transactionFee2,
    transactionFeeType2: gateway.transactionFeeType2,
  };
};

export type GetTotalsReturn = {
  orderTotal: number;
  usedPoints: number;
  userPoints: number;
  remainingPoints: number;
  usedCredits: number;
  userCredits: number;
  remainingCredits: number;
  totalWithTransactionFee: number;
  remainingPayment: number;
  transactionFee: TransactionFee;
  remainingPaymentWithoutTransactionFee: number;
  deliveryFee: number;
};

type GetTotalsProps = {
  step: number;
  order: OrderQuery["order"];
  method: Method;
  gateway: OrderGateway;
  gateways:
    | OrderQuery["order"]["membership"]["gateways"]
    | OrderQuery["order"]["event"]["gateways"];
  referralReduction?: number;
  userCredits: number;
  userPoints: number;
  partialPaymentMethod: Method;
  cart?: Cart;
};

export const getTotals = ({
  step,
  order,
  method,
  gateway,
  gateways,
  userPoints = 0,
  userCredits = 0,
  partialPaymentMethod,
  cart,
}: GetTotalsProps): GetTotalsReturn => {
  // Calculate cart totals client side on step2 or below
  if (cart && step <= 2) {
    const usedCredits = userCredits >= cart.total ? cart.total : userCredits;

    let usedPoints = 0;

    if (method === "point") {
      const orderTotalMinusCredit = cart.total - usedCredits;
      usedPoints =
        userPoints > orderTotalMinusCredit ? orderTotalMinusCredit : userPoints;
    }

    const deliveryFee =
      (order?.deliveryFee ?? 0) +
      (cart.smsConfirmationFeeApplied ? cart.smsConfirmationFee : 0);

    return {
      orderTotal: cart.total,
      usedPoints,
      usedCredits,
      userCredits,
      userPoints,
      remainingCredits: userCredits - usedCredits,
      remainingPoints: userPoints - usedPoints,
      totalWithTransactionFee: cart.total - usedCredits - usedPoints,
      transactionFee: {
        ...cart.defaultTransactionFeeGateway,
        transactionFeeAmount: cart.defaultTransactionFee,
      },
      remainingPayment: cart.total,
      remainingPaymentWithoutTransactionFee: cart.total,
      deliveryFee,
    };
  }

  const selectedGateway = partialPaymentMethod
    ? gateways?.find((gateway) => partialPaymentMethod.startsWith(gateway.id))
    : gateway;

  const paymentOptionState = usePaymentOptionsStore.getState();

  // Get total of all line items (including ticket fees)
  const lineItemsTotal = Number(
    order?.lineItems?.edges
      ?.reduce((acc, lineItem) => (acc += lineItem?.node?.total ?? 0), 0)
      ?.toFixed(2)
  );
  let totalWithOrderFees =
    lineItemsTotal +
    (order?.deliveryFee ?? 0) +
    (order?.transferFee ?? 0) +
    (order?.customTaxAmount ?? 0) +
    (order?.tax?.totalExclusive ?? 0) +
    (order?.orderTicketCover?.totalFeeAmount ?? 0) +
    (order?.smsConfirmationAmount ?? 0) +
    (order?.bookingFeeAmount ?? 0) -
    (order?.changingSeatsCredits ?? 0);

  let paymentPlanFeeAmount = 0;
  if (
    paymentOptionState?.selectedPaymentOption?.paymentPlanMetadata
      ?.paymentPlanFeePercentage
  ) {
    paymentPlanFeeAmount = +(
      (paymentOptionState.selectedPaymentOption.paymentPlanMetadata
        .paymentPlanFeePercentage /
        100) *
      totalWithOrderFees
    ).toFixed(2);

    usePaymentOptionsStore.setState({
      ...paymentOptionState,
      paymentPlanFeeAmount,
    });

    totalWithOrderFees += paymentPlanFeeAmount;
  } else {
    usePaymentOptionsStore.setState({
      ...paymentOptionState,
      paymentPlanFeeAmount: undefined,
      selectedPaymentOption: undefined,
    });
  }

  const transactionFee = getTransactionFeeAmount({
    total: totalWithOrderFees,
    gateway: selectedGateway
      ? {
          ...selectedGateway,
          transactionFee2: getTransactionFee2(
            method ?? partialPaymentMethod,
            order,
            selectedGateway
          ),
        }
      : null,
    paymentCount: getPaymentCount(method ?? partialPaymentMethod, order),
  });

  const orderTotal = order?.total + paymentPlanFeeAmount;

  const totalWithTransactionFee =
    order?.channel === OrderChannel.Pos
      ? orderTotal
      : orderTotal +
        ((transactionFee?.transactionFeeAmount || 0) -
          (order?.transactionFeeAmount ?? 0));

  const usedCredits =
    userCredits >= totalWithTransactionFee
      ? totalWithTransactionFee
      : userCredits;

  let usedPoints = 0;

  if (method === "point") {
    const orderTotalMinusCredit = orderTotal - usedCredits;
    usedPoints =
      userPoints > orderTotalMinusCredit ? orderTotalMinusCredit : userPoints;
  }

  const remainingPayment = totalWithTransactionFee - usedCredits - usedPoints;
  const remainingPaymentWithoutTransactionFee =
    orderTotal - usedCredits - usedPoints;

  return {
    orderTotal,
    usedPoints,
    usedCredits,
    userCredits,
    userPoints,
    remainingCredits: userCredits - usedCredits,
    remainingPoints: userPoints - usedPoints,
    totalWithTransactionFee,
    transactionFee,
    remainingPayment,
    remainingPaymentWithoutTransactionFee,
    deliveryFee:
      (order?.deliveryFee ?? 0) + (order?.smsConfirmationAmount ?? 0),
  };
};
