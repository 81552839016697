import * as Sentry from "@sentry/nextjs";
import { GraphQLClient } from "graphql-request";
import { IncomingMessage } from "http";
import { GetStaticProps } from "next";
import { graphUrl } from "~config";
import { getSdk, SdkFunctionWrapper } from "~graphql/sdk";
import { extractGraphQLErrorProperties } from "~lib/helpers";

// User agent for calls to our flicket-backend from serverless functions
const serverSideUserAgent = `FlicketCustomerPortal (Next.js; Vercel; Internal-API-Client; contact=support@flicket.io)`;

export const client = (
  orgId: string | undefined = undefined,
  host: string | undefined = undefined,
  recaptchaToken: string | undefined = undefined,
  transactionId: string | undefined = undefined
) =>
  new GraphQLClient(graphUrl, {
    credentials: "include",
    headers: {
      ...(orgId
        ? {
            "Flicket-Org-Id": orgId,
          }
        : {}),
      ...(host
        ? {
            origin: host,
          }
        : {}),
      ...(process.env.VERCEL_USER_TOKEN
        ? { "user-token": process.env.VERCEL_USER_TOKEN }
        : { "user-token": "PLACEHOLDER" }),
      ...(recaptchaToken ? { "g-recaptcha-response": recaptchaToken } : {}),
      ...(transactionId ? { "transaction-id": transactionId } : {}),
    },
  });

const sentryWrapper: SdkFunctionWrapper = async <T>(
  action: () => Promise<T>
) => {
  return action().catch((error) => {
    const { status, statusCode, code } = extractGraphQLErrorProperties(
      error as Error
    );

    Sentry.captureException(error, {
      tags: {
        status,
        statusCode,
        code,
      },
    });

    throw error;
  });
};

export interface SDKOptions {
  orgId?: string;
  host?: string;
  recaptchaToken?: string;
  server?: {
    req: IncomingMessage;
    cookie?: string; // This is just the regular cookie, but its called server cookie since it's only required to be passed explicitly for server-side calls.
  };
}

export type SDKMethod = keyof ReturnType<typeof sdk>;
export type SDKGeneratorFn = (
  method?: SDKMethod
) => Promise<
  (options: Omit<SDKOptions, "recaptchaToken">) => ReturnType<typeof sdk>
>;

export const sdk = ({
  orgId,
  host,
  recaptchaToken,
  server,
}: SDKOptions = {}) => {
  const transactionId = Math.random().toString(36).substring(2, 9);
  Sentry.configureScope((scope) => {
    scope.setTag("transaction-id", transactionId);
  });

  const gqlClient = client(orgId, host, recaptchaToken, transactionId);

  // Executing in vercels serverless functions
  // Forward headers to our server
  if (server) {
    gqlClient.setHeader("User-Agent", serverSideUserAgent);

    const headersToForward = [
      "x-forwarded-host",
      "x-forwarded-for",
      "x-forwarded-proto",
      "x-vercel-ip-country",
    ];

    headersToForward.forEach((header) => {
      const value = server.req?.headers?.[header];

      if (value) {
        const headerValue = Array.isArray(value) ? value.join(", ") : value;
        gqlClient.setHeader(header, headerValue);
      }
    });

    if (server.cookie) {
      gqlClient.setHeader("cookie", server.cookie);
    }
  }

  return getSdk(gqlClient, sentryWrapper);
};

export const getStaticPropsWithSDK = async (
  func: (sdk: ReturnType<typeof getSdk>) => ReturnType<GetStaticProps>
): Promise<ReturnType<GetStaticProps>> => {
  const staticSDK = getSdk(client());
  return func(staticSDK);
};
