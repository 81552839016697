import { Box, Link, system, SystemProps, RoundedButton } from "flicket-ui";
import Image from "next/future/image";
import { ReactNode, useContext, useState } from "react";
import styled from "styled-components";
import { Icon } from "~components/common/Icon";
import { Sidebar } from "~components/common/Menu/menu.Sidebar";
import { BaseContext } from "~context";
import { getImageSrc } from "~lib/helpers";

import { FixedSizeImage } from "../Image";
import { useOrganization } from "~hooks";
import { IdentificationBadge } from "@phosphor-icons/react";

interface MenuProps extends SystemProps {
  isLoggedIn: boolean;
  pathname: string;
  logout: () => void;
  children: ReactNode;
}

const StyledMenu = styled(Box)<SystemProps>`
  width: 100%;
  padding: 16px 16px 16px 16px;

  height: 128px;
  max-height: 128px;

  /* background-color: ${(p) => p.theme.colors.P300}; */

  box-shadow: ${(p) => p.theme.shadows.sm};

  font-size: ${(p) => p.theme.fontSizes[3]};
  letter-spacing: -0.165px;

  position: relative;

  ${system}
`;

const MobileBackground = styled(Box)`
  position: absolute;

  max-height: 128px;
  height: 128px;

  top: 0;
  left: 0;

  width: 100%;

  background-color: ${(p) => p.theme.colors.P300};

  z-index: 1;
`;

const visiblePaths = [
  "/",
  "/events",
  "/merchandise",
  "/memberships",
  "/members-area",
  "/not-found",
  "/404",
  "/account",
  "/tickets",
  "/order",
  "/tours/[tourId]",
  "/calendar",
];

export const MobileMenu = ({
  isLoggedIn,
  logout,
  pathname = "/",
  children,
  ...props
}: MenuProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { organization, t } = useOrganization();
  const { isSinglePage } = useContext(BaseContext);
  const { mobileLogo, background } = organization?.branding || {};

  // this is needed so we have the menu header for a single event set up.
  if (isSinglePage) {
    visiblePaths.push("/events/[eventId]");
  }

  const openMenu = () => setIsOpen(true);
  const closeMenu = () => setIsOpen(false);
  const bgImageUrl = getImageSrc(organization?.branding?.background, "");
  return (
    visiblePaths.includes(pathname) && (
      <StyledMenu d="flex" flexDirection="column" {...props}>
        <MobileBackground>
          {bgImageUrl && (
            <Image
              src={bgImageUrl}
              fill={true}
              alt={`${organization.name}`}
              priority={true}
              style={{ objectFit: "cover", objectPosition: "center" }}
            />
          )}
        </MobileBackground>

        <Sidebar
          isLoggedIn={isLoggedIn}
          isOpen={isOpen}
          logout={logout}
          onClose={closeMenu}
        />
        <Box
          d="flex"
          alignItems="center"
          justifyContent="space-between"
          zIndex={"2" as any}
        >
          <Box d="flex">
            <Icon
              fontSize={6}
              icon="menu"
              color="white"
              mr="6/4"
              onClick={openMenu}
            />
            <Link
              to={
                organization?.slug === "artfair"
                  ? "http://www.artfair.co.nz"
                  : "/"
              }
            >
              <FixedSizeImage
                image={mobileLogo}
                fallback="/static/flicket-white.png"
                alt={`${organization.name} logo`}
                sizes={"(min-width: 200px) 200px"}
                priority={true}
                width={60}
                height={40}
              />
            </Link>
          </Box>
          {!isSinglePage && !!organization?.showMemberships && (
            <Box d="flex" alignItems="center">
              <RoundedButton
                ml={4}
                size="md"
                variant={background ? "primary" : "solid"}
                href={
                  isLoggedIn
                    ? "/members-area"
                    : "/login?redirect=%2Fmembers-area"
                }
              >
                <IdentificationBadge weight="fill" />
                {t("membership_name_plural")} Area
              </RoundedButton>
            </Box>
          )}
        </Box>
        {children}
      </StyledMenu>
    )
  );
};
