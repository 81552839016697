import {
  DialogContent as ReachDialogContent,
  DialogOverlay as ReachDialogOverlay,
} from "@reach/dialog";
import { Box, Link, Text, LegacyText, TransparentButton } from "flicket-ui";
import { AnimatePresence, motion } from "framer-motion";
import { useContext } from "react";
import div100VH from "react-div-100vh";
import styled from "styled-components";

import { Icon } from "~components/common/Icon";
import { BaseContext } from "~context";
import { useAccount } from "~hooks";
import { getUrl } from "~lib/helpers";
import { FixedSizeImage } from "../Image";

const MotionOverlay = motion(ReachDialogOverlay);
const MotionDialog = motion(ReachDialogContent);

const DialogOverlay = styled(MotionOverlay)<{ isOpen: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;

  width: 100%;
  min-height: 100%;

  z-index: ${(p) => p.theme.zIndices.popover};
  /* pointer-events: ${(p) => (p.isOpen ? "initial" : "none")}; */

  && {
    background: rgba(0, 0, 0, 0.6);
  }
`;

const DialogContent = styled(MotionDialog)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;

  height: 100vh;
  width: 70%;
  max-width: 280px;
  z-index: ${(p) => p.theme.zIndices.popover + 1};

  && {
    padding: 0;
    background: white;
  }

  @media (min-width: ${(p) => p.theme.breakpoints.md}) {
    width: 70%;
  }

  @media (min-width: ${(p) => p.theme.breakpoints.lg}) {
    width: 55%;
  }
`;

const StyledLink = styled(TransparentButton)`
  display: flex;
  align-items: center;
`;

const StyledSidebar = styled(div100VH)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;
  max-width: 280px;

  padding: 24px 16px 42px;

  background-color: ${(p) => p.theme.colors.white};

  box-shadow: ${(p) => p.theme.shadows.reversed};

  z-index: 1;

  @media (max-width: ${(p) => p.theme.breakpoints.xxs}) {
    width: 80vw;
  }
`;

const StyledLinkBlock = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  padding: 12px 0;

  border-top: 1px solid ${(p) => p.theme.colors.N200};

  color: ${(p) => p.theme.colors.N800};

  &:last-of-type {
    border-bottom: 1px solid ${(p) => p.theme.colors.N200};
  }

  &:hover {
    opacity: 1;

    color: ${(p) => p.theme.colors.P300};
  }
`;

export interface ModalProps {
  isLoggedIn: boolean;
  isOpen: boolean;
  height?: any;
  width?: any;
  size?: any;
  aria?: string;
  onClose?: any;
  logout: () => void;
}

export const Sidebar = ({
  isOpen,
  onClose,
  aria,
  isLoggedIn,
  logout,
  ...props
}: ModalProps) => {
  const { organization } = useContext(BaseContext);
  const { isAdmin } = useAccount();

  const linkProps = {
    onClick: () => {
      onClose();
    },
  };

  const renderLinks = () => (
    <Box>
      {isLoggedIn ? (
        <>
          <StyledLinkBlock {...linkProps} to="/account">
            <Box d="flex">
              <Icon icon="account" fontSize={6} mr={3} />
              <Box>
                <LegacyText variant="heavy.M" mb="1/2" lineHeight="normal">
                  My Account
                </LegacyText>
                <LegacyText fontSize={1} color="N600">
                  Set your personal details
                </LegacyText>
              </Box>
            </Box>
            <Icon icon="chevron-right" color="P300" fontSize={6} />
          </StyledLinkBlock>
          <StyledLinkBlock {...linkProps} to="/tickets">
            <Box d="flex">
              <Icon icon="my-tickets" fontSize={6} mr={3} />
              <Box>
                <LegacyText variant="heavy.M" mb="1/2" lineHeight="normal">
                  My Tickets
                </LegacyText>
                <LegacyText fontSize={1} color="N600">
                  View my tickets for all events
                </LegacyText>
              </Box>
            </Box>
            <Icon icon="chevron-right" color="P300" fontSize={6} />
          </StyledLinkBlock>
          {isAdmin && (
            <StyledLinkBlock
              {...linkProps}
              href={`${getUrl(organization?.slug)}`}
            >
              <Box d="flex">
                <Icon icon="admin-panel" fontSize={6} mr={3} />
                <Box>
                  <LegacyText variant="heavy.M" mb="1/2" lineHeight="normal">
                    Admin panel
                  </LegacyText>
                  <LegacyText fontSize={1} color="N600">
                    Manage the customer portal
                  </LegacyText>
                </Box>
              </Box>
              <Icon icon="chevron-right" color="P300" fontSize={6} />
            </StyledLinkBlock>
          )}
        </>
      ) : (
        <>
          <StyledLinkBlock {...linkProps} to="/login">
            <Box d="flex">
              <Icon icon="account" fontSize={6} mr={3} />
              <Box>
                <LegacyText variant="heavy.M" mb="1/2" lineHeight="normal">
                  Log in
                </LegacyText>
                <LegacyText fontSize={1} color="N600">
                  Log in using an existing account
                </LegacyText>
              </Box>
            </Box>
            <Icon icon="chevron-right" color="P300" fontSize={6} />
          </StyledLinkBlock>
          <StyledLinkBlock {...linkProps} to="/signup">
            <Box d="flex">
              <Icon icon="membership" fontSize={6} mr={3} />
              <Box>
                <LegacyText variant="heavy.M" mb="1/2" lineHeight="normal">
                  Create an account
                </LegacyText>
                <LegacyText fontSize={1} color="N600">
                  Create a new account with {organization?.name || "Flicket"}
                </LegacyText>
              </Box>
            </Box>
            <Icon icon="chevron-right" color="P300" fontSize={6} />
          </StyledLinkBlock>
        </>
      )}
    </Box>
  );

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <DialogOverlay
            isOpen={isOpen}
            onDismiss={onClose}
            initial={{ opacity: 0, pointerEvents: "auto" }}
            animate={{ opacity: 1, pointerEvents: "auto" }}
            exit={{ opacity: 0, pointerEvents: "none" }}
          >
            <DialogContent
              {...props}
              aria-label={aria || "dialog content"}
              initial={{ x: "-100%" }}
              animate={{ x: 0 }}
              exit={{ x: "-100%" }}
              transition={{
                x: { type: "spring", stiffness: 300, damping: 50 },
              }}
            >
              <StyledSidebar>
                <Box>
                  <Box d="flex" justifyContent="space-between" mb={4}>
                    <Icon
                      icon="close"
                      fontSize={6}
                      width="24px"
                      height="24px"
                      onClick={onClose}
                    >
                      x
                    </Icon>
                    <Link to="/">
                      <FixedSizeImage
                        image={organization.branding?.logo}
                        fallback="/static/flicket-white.png"
                        alt={`${organization.name} logo`}
                        priority={true}
                        width={80}
                        height={24}
                      />
                    </Link>
                  </Box>
                  {renderLinks()}
                </Box>
                {isLoggedIn && (
                  <StyledLink
                    as="a"
                    onClick={() => {
                      onClose();
                      logout();
                    }}
                  >
                    <Icon icon="logout" fontSize={6} mr={1} />
                    <LegacyText variant="heavy.M">Log out</LegacyText>
                  </StyledLink>
                )}
              </StyledSidebar>
            </DialogContent>
          </DialogOverlay>
        </>
      )}
    </AnimatePresence>
  );
};
