import { parseISO } from "date-fns";
import { format, toZonedTime } from "date-fns-tz";
import { enNZ } from "date-fns/locale";
import {
  EventDateRangeOptions,
  FormatDateOptions,
  FormatType,
  ZonedDate,
} from "@flicket/utils";
import { i18n } from "~lib/i18n";
import { VenueSeating } from "~graphql/sdk";
import { convertTo12HrTime } from "./dates";
import { DOT_SEPARATOR } from "./constants";

/**
 * Formats the given date based on the en-NZ locale and timezone into a human readable formatted date string.
 *
 * Uses format and toDate from 'date-fns-tz' internally
 *
 * @param date - ISO string representing UTC time
 * @param dateFormat - defaults to "dd/MM/yyyy"
 *
 * @returns A string representing the formatted date in the Organization timezone
 *
 * @remarks
 * This functions works based on a **fixed time zone**
 * This is done because events should always be displayed in the time zone where the events takes place
 */
export const formatDate = (date: string | Date, dateFormat = "dd/MM/yyyy") => {
  const timeZone = i18n.timezone;

  return (
    date &&
    format(
      toZonedTime(typeof date === "string" ? parseISO(date) : date, timeZone),
      dateFormat,
      {
        locale: enNZ,
        timeZone,
      }
    )
  );
};

export function formatEventDateRange(
  startDate: string,
  endDate: string,
  options: EventDateRangeOptions &
    // Make venue seating required when using doorsOpen time
    (| { doorsOpen: string; seating: VenueSeating }
      | { doorsOpen?: never; seating?: never }
    )
) {
  const { doorsOpen, seating, ...dateOptions } = options;

  const doorsOpenTime = getDoorsOpenTimeString(doorsOpen, seating);

  const dateString = ZonedDate.formatEventRange(startDate, endDate, {
    format: "basicShort",
    ...dateOptions,
    timeZone: options.timeZone ?? i18n.timezone,
    locale: options.locale ?? i18n.locale,
  });

  if (doorsOpenTime) {
    return `${dateString} ${DOT_SEPARATOR} ${doorsOpenTime}`;
  } else {
    return dateString;
  }
}

export function formatEventDate(
  date: string,
  type: FormatType = "date",
  options: FormatDateOptions
) {
  return ZonedDate.format(date, type, {
    ...options,
    timeZone: options.timeZone ?? i18n.timezone,
    locale: options.locale ?? i18n.locale,
  });
}

export function formatOrgDate(
  date: string,
  type: FormatType = "date",
  options?: Partial<FormatDateOptions>
) {
  return ZonedDate.format(date, type, {
    ...options,
    timeZone: i18n.timezone,
    locale: i18n.locale,
  });
}

export function getDoorsOpenTimeString(
  doorsOpen24HrTime: string,
  seating: VenueSeating
) {
  if (!doorsOpen24HrTime) return "";
  const doorsPrefix =
    seating === VenueSeating.Seated ? "Gate opens" : "Doors open";
  const doorsOpenTime = `${doorsPrefix} ${convertTo12HrTime(
    doorsOpen24HrTime
  )}`;
  return doorsOpenTime;
}
