import React, { PropsWithChildren } from "react";

import { Flex, Spinner, Text, LegacyText } from "flicket-ui";

import { getError } from "~lib";

interface StatusProps {
  loading: boolean;
  error: any;
  noResult?: boolean;
  noResultText?: string;
}

export const Status = ({
  children,
  loading,
  error,
  noResult = false,
  noResultText,
}: PropsWithChildren<StatusProps>) => {
  if (loading) {
    return (
      <Flex variant="center" flex="1" p={4} flexDirection="column">
        <Spinner size={48} color="P300" data-testid="spinner" />
      </Flex>
    );
  }

  if (error) {
    return (
      <Flex
        variant="center"
        flex="1"
        py={{ _: 2, md: 4 }}
        px={2}
        flexDirection="column"
      >
        <LegacyText variant="title.S">Error</LegacyText>
        <LegacyText variant="title.XS">{getError(error, "graphQL")}</LegacyText>
      </Flex>
    );
  }

  if (noResult) {
    return (
      <Flex
        variant="center"
        flex="1"
        py={{ _: 2, md: 4 }}
        px={2}
        flexDirection="column"
      >
        <LegacyText variant="title.S">
          {noResultText || "No events are available."}
        </LegacyText>
      </Flex>
    );
  }

  return <>{children}</>;
};
