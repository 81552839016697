import { Flex, PrimaryButton, Text, LegacyText } from "flicket-ui";
import startCase from "lodash/startCase";
import { showToast } from "~lib";

interface DigitalPassCodeDisplayProps {
  variant: "event" | "membership";
  url: string;
}

export const DigitalPassCodeDisplay = ({
  url,
  variant,
}: DigitalPassCodeDisplayProps) => {
  const copyToLinkClipboard = () => {
    void navigator.clipboard.writeText(url);
    showToast("Link copied!", "success");
  };

  return (
    <Flex flexDirection={"column"} alignItems={"center"}>
      <PrimaryButton
        marginTop={4}
        onClick={() => {
          window.open(url, "_blank");
        }}
      >
        Go To {startCase(variant)}
      </PrimaryButton>
      <br />
      <LegacyText
        variant="medium.S"
        onClick={copyToLinkClipboard}
        cursor="pointer"
        paddingTop={1}
      >
        Or click here to copy link to clipboard
      </LegacyText>
    </Flex>
  );
};
