import { Flex, Text, LegacyText, SecondaryButton, PrimaryButton } from "flicket-ui";

import { Footer, Seo } from "~components";
import { marketingUrl } from "~config";
import { Layout } from "~components/common/Layout";

const NoOrganization = () => {
  return (
    <>
      <Seo
        title="Organization not found"
        description="Organization was not found"
      />

      <Layout layoutType="main">
        <Flex
          w="100%"
          alignItems="center"
          flexDir="column"
          justifyContent={{ _: "flex-start", md: "center" }}
          flex="1"
          px={{ _: 2, md: 4 }}
          py={2}
          bg="N100"
        >
          <Flex
            alignItems="center"
            flexDir="column"
            justifyContent="center"
            w="100%"
            maxW="480px"
            bg="white"
            px={{ _: 2, md: 4 }}
            py={4}
            borderRadius="lg"
            boxShadow="container"
          >
            <LegacyText
              fontWeight="demiBold"
              textAlign="center"
              fontSize={6}
              mb={{ _: 0, md: 1 }}
            >
              404
            </LegacyText>
            <LegacyText textAlign="center">
              We could not find the organization you were looking for.
            </LegacyText>

            <Flex justifyContent="center" mt={{ _: 2, lg: 3 }}>
              <SecondaryButton href={marketingUrl} mr={2}>
                Back to main site
              </SecondaryButton>
              <PrimaryButton href="/events">Back to events</PrimaryButton>
            </Flex>
          </Flex>
        </Flex>
        <Footer />
      </Layout>
    </>
  );
};

export default NoOrganization;
