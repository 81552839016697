export const marketingUrl =
  process.env.MARKETING_URL || "https://flicket.co.nz";

export function marketingOptInCopy(
  organization: { name: string },
  type: "email" | "sms" | "full",
  includeExpoExhibitors = false
): string {
  const name = organization.name ?? "Flicket";
  const exhibitorsText = " and their exhibitors ";
  if (type === "full") {
    return `I'd like to receive updates on ${name} news, events, and offers via email and text messages`;
  }
  const labelForType = type === "email" ? "email" : "text message";
  return `${name}${
    includeExpoExhibitors ? exhibitorsText : ""
  } can send me ${labelForType} marketing.`;
}
