/* eslint react/prop-types: 0 */
import { omit, pick } from "@styled-system/props";
import { Box, StatusMessage, SystemProps, Text, LegacyText } from "flicket-ui";
import { forwardRef } from "react";
import { Props } from "react-select";

import { useId, useIsMobile } from "~hooks";

import StyledSelect from "./components/StyledSelect";

type status = "default" | "success" | "warning" | "error";

export type Option = {
  label: string;
  value: string;
  options?: Option[];
};

interface SelectProps extends SystemProps, Props<Option> {
  status?: status;
  statusMessage?: string;
  defaultValue?: any;
  label?: string;
  labelVariant?: string;
  // html props
  // @todo figure out how to combine styled system with React.HTMLProps
  disabled?: boolean;
  onChange?: (val?) => any;
  placeholder?: string;
  small?: boolean;
  type?: "email" | "password";
  error?: string;
  selectProps: any;
  useSingleValue?: boolean;
}

const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      status,
      statusMessage = false,
      label = false,
      labelVariant,
      small = false,
      error = false,
      selectProps,
      disabled,
      ...props
    },
    ref
  ) => {
    const id = useId();
    const isMobile = useIsMobile();

    const onChange = (selectedOption: Option | Option[] | null) => {
      if (!selectedOption) {
        props.onChange(undefined);
        return;
      }

      // Backwards compatibility for when the value is an object
      if (!props.useSingleValue) {
        props.onChange(selectedOption);
        return;
      }

      const singleValue = props.isMulti
        ? (selectedOption as Option[]).map(({ value }) => value) || []
        : (selectedOption as Option).value;

      props.onChange(singleValue);
    };

    // Extract the value from the options object as react-select requires
    // an selected object not a single value.
    const value = (() => {
      if (props.isMulti && Array.isArray(props.value)) {
        return props.value?.map((value: string) =>
          getSelectedOption(value, props.options as Option[])
        );
      }

      // TODO: remove when upgrade is complete, this is for backwards compatibility
      if (!props.useSingleValue) {
        return props.value;
      }

      return getSelectedOption(props.value, props.options as Option[]);
    })();

    return (
      <Box w="100%" {...pick(props)}>
        {label && (
          <LegacyText
            fontSize={3}
            fontWeight="extraBold"
            color="N600"
            display="block"
            htmlFor={`select-${id}`}
            pb={1}
            as="label"
          >
            {label}
          </LegacyText>
        )}

        <StyledSelect
          ref={ref}
          inputId={`select-${id}`}
          {...omit(props)}
          small={small}
          isSearchable={!isMobile}
          hideSelectedOptions={false}
          isDisabled={disabled}
          {...selectProps}
          // The option below can be used to controll
          // the auto-placement of the dropdown. i.e.
          // if the select is at the bottom of the page
          // then the menu will pop UP.
          // menuPlacement="auto"

          {...(props.defaultValue && {
            defaultValue: getSelectedOption(
              props.defaultValue,
              props.options as Option[]
            ),
          })}
          {...(value && { value: value || [] })}
          onChange={onChange}
        />

        {statusMessage && (
          <StatusMessage status={status}>{statusMessage}</StatusMessage>
        )}

        {error && <StatusMessage status="error">{error}</StatusMessage>}
      </Box>
    );
  }
);

Select.displayName = "Select";

export { Select };

// This is a helper function to get the selected object from the options object
// based on the `value` prop. This is need because react-select only works by
// passing the selected object and not simply the `value` prop 👻.
function getSelectedOption(value, options: Option[]) {
  // TODO: remove when upgrade is complete, this is for backwards compatibility
  if (typeof value === "object") return value as Option;

  // Normalise options to a flat array
  const flatOptions: Option[] = options[0]?.options
    ? options.reduce(
        (acc, option) => acc.concat(option.options) as Option[],
        []
      )
    : options;

  return flatOptions.find((option) => option.value === value);
}
