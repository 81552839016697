import { useQuery } from "~hooks/useQuery";
import {
  EventAccessStatus,
  GetEventAndReleaseForCustomerDocument,
} from "~graphql/typed-document-nodes";
import { showToast } from "~lib";
import { useRouter } from "next/router";
import { ToastType } from "react-toastify";
import * as actions from "~features/nonseated-reservation/store/actions";
import { useSDK } from "./useSDK";
import {
  GetEventAndReleaseForCustomerQueryVariables,
  ReleaseType,
} from "~graphql/sdk";
import { ReleaseStartCondition } from "../graphql/typed-document-nodes";
import { formatEventDate } from "../lib/helpers/formatDate";
import { Box } from "flicket-ui";

type QueryParams = {
  eventId?: string;
  release?: string;
  slug?: string;
  code?: string;
};

export const useGetEventWithAccessControl = (userEventId?: string) => {
  const router = useRouter();
  const sdk = useSDK();
  const query = router.query as QueryParams;

  const eventId = query.eventId ?? userEventId;
  const releaseId = query.release;
  const accessCode = query.code ? decodeURIComponent(query.code) : undefined;

  const { isLoading, data, error, isValidating } = useQuery(
    eventId ? GetEventAndReleaseForCustomerDocument : null,
    {
      input: {
        eventId,
        releaseId,
        releaseSlug: query.slug,
        code: accessCode,
      },
    },
    {
      fetcher: async (input: GetEventAndReleaseForCustomerQueryVariables) => {
        const res = await sdk.getEventAndReleaseForCustomer(input);
        const {
          release,
          redirect,
          accessType,
          isAdmin,
        } = res.getEventAndReleaseForCustomer;

        if (!release && router.pathname.includes("/reservation") && !isAdmin) {
          void showToast("Tickets are not yet available.", ToastType.ERROR);
          await router.replace(`/events/${eventId}`);
        }

        if (accessType === EventAccessStatus.Denied) {
          void showToast(
            "This event is not public. Admin only access.",
            ToastType.ERROR
          );
          await router.replace("/");
        }

        if (accessCode) {
          const passwordLookup = await sdk.validateReleasePassword({
            id: release.id,
            password: accessCode,
          });
          if (passwordLookup?.validateReleasePassword?.id) {
            sessionStorage.setItem(
              "releasePasswordId",
              passwordLookup.validateReleasePassword?.id
            );
          }
        }

        if (accessType === EventAccessStatus.ShouldRedirect) {
          const { slug, code, eventId } = redirect;

          const query = {
            ...router.query,
            eventId,
            ...(slug && { slug }),
            ...(code && { code: encodeURIComponent(code) }),
          };

          await router.replace({
            pathname: router.pathname,
            query,
          });
        } else if (accessType === EventAccessStatus.EventEnded) {
          void showToast("This event has finished.", ToastType.ERROR);
          await router.replace("/");
        } else if (
          !release &&
          router.pathname.includes("/reservation") &&
          !isAdmin
        ) {
          void showToast("Tickets are not yet available.", ToastType.ERROR);
          await router.replace(`/events/${eventId}`);
        }

        /**
         * Specific logic for future presales
         */
        if (release?.type === ReleaseType.Presale) {
          const startDateInFuture =
            release.startCondition === ReleaseStartCondition.Date &&
            release.startConditionDate &&
            new Date().getTime() <
              new Date(release.startConditionDate).getTime();

          if (startDateInFuture) {
            const startDate = formatEventDate(
              release.startConditionDate,
              "datetime",
              {
                timeZone: release.event?.venue?.timezone,
                locale: release.event?.venue?.locale,
                timeZoneName: "short",
              }
            );
            showToast(
              <>
                <Box mb={1} fontWeight="bold">
                  Presale not yet open.
                </Box>
                Tickets will be available starting {startDate}.<br />
                Please check back then to secure your tickets!
              </>,
              ToastType.WARNING,
              {
                autoClose: 15000, // 15 secs, adequate time for the user to read the toast
              }
            );
            await router.replace("/");
          }
        }

        return res;
      },
      onSuccess: (data) => {
        const { event, release } = data.getEventAndReleaseForCustomer;
        actions.updateTrackingContext({
          id: event.id,
          name: event.title,
          releaseId: release?.id,
          releaseName: release?.name,
        });
      },
    }
  );

  const { event, release, isAdmin, accessType } =
    data?.getEventAndReleaseForCustomer ?? {};

  return {
    isLoading,
    release,
    isAdmin,
    event,
    error,
    isValidating,
    accessType,
  };
};
