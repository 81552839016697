import {
  CommerceActivityTrackingEventProductMetadata,
  ConvergeSdk,
  PersonIdentifiers,
} from "@seeka-labs/converge";
import {
  SeekaProvider,
  SeekaProviderConfigContext,
} from "@seeka-labs/converge-react";
import { ReactNode } from "react";
import { WaitListFormValues } from "~components/events/waitlist/interface";
import { SeatsIOSeat } from "~components/reservation/constants";
import {
  GetOrderJobStatusQuery,
  JoinResaleWaitlistMutation,
  MembershipQuery,
  OrderQuery,
  OrderStatus,
  OrderType,
  SeekaConfigFragment,
} from "~graphql/typed-document-nodes";
import { JoinWaitlistMutation } from "~graphql/sdk";

// Seeka runs on ALL pages, however these pages have their own provider with event/membership level scope.
const seekaSpecificPathnames = [
  "/",
  "/events/[eventId]",
  "/events/[eventId]/reservation",
  "/events/[eventId]/registration",
  "/events/[eventId]/ticket-waitlist",
  "/events/[eventId]/tickets",
  "/memberships/[membershipId]",
  "/memberships/[membershipId]/reservation",
  "/checkout/[orderId]",
];

export const orgLevelSeeka = (pathname: string) => {
  return !seekaSpecificPathnames.includes(pathname);
};

// This is set for pages that don't have event/membership specific setups.
export function OptionalSeekaProvider({
  enabled = true,
  seekaConfig,
  children,
}: {
  enabled?: boolean;
  seekaConfig: SeekaConfigFragment;
  children: ReactNode;
}) {
  if (enabled && seekaConfig) {
    return (
      <SeekaProvider {...seekaProviderConfig(seekaConfig)}>
        {children}
      </SeekaProvider>
    );
  }

  return <>{children}</>;
}

export function seekaProviderConfig(
  seekaConfig: SeekaConfigFragment
): {
  id: string;
  publicKey: string;
  org: string;
  context?: SeekaProviderConfigContext;
} {
  if (!seekaConfig) {
    return {
      id: undefined,
      publicKey: undefined,
      org: undefined,
    };
  }

  return {
    id: seekaConfig.seekaInstanceId,
    org: seekaConfig.seekaOrganizationId,
    publicKey: seekaConfig.seekaPublicKey,
    context: seekaContext(seekaConfig),
  };
}

function seekaContext(
  seekaConfig: SeekaConfigFragment
): SeekaProviderConfigContext {
  return {
    tracking: {
      defaults: {
        currencyCode: seekaConfig.currencyCode,
        countryCode: seekaConfig.countryCode,
      },
    },
    client: {
      ver: process.env.GIT_COMMIT_SHA,
      type: "flicket",
    },
  };
}

function lineItemToSeekaProduct(
  order:
    | OrderQuery["order"]
    | GetOrderJobStatusQuery["getOrderJobStatus"]["order"],
  lineItem: OrderQuery["order"]["lineItems"]["edges"][0]["node"]
): CommerceActivityTrackingEventProductMetadata {
  return {
    variantIdentifier:
      lineItem.ticketType?.id ??
      lineItem.membershipType?.id ??
      lineItem.eventAddon?.id ??
      lineItem.membershipAddon?.id,
    variantName:
      lineItem.ticketType?.name ??
      lineItem.membershipType?.membership?.name ??
      lineItem.eventAddon?.name ??
      lineItem.membershipAddon?.name,
    quantity: lineItem.quantity,
    unitPrice: lineItem.price,
    productIdentifier: order.event?.id ?? order.membership?.id,
    productName: order?.event?.title ?? order.membership?.name,
  };
}

export function seekaTrackCheckout(
  converge: ConvergeSdk | undefined,
  order: GetOrderJobStatusQuery["getOrderJobStatus"]["order"],
  currencyCode: string
) {
  if (!converge) {
    return;
  }

  if (order.orderType !== OrderType.Default) {
    return;
  }

  const groupedProducts: {
    [key: string]: CommerceActivityTrackingEventProductMetadata;
  } = {};

  order?.lineItems?.edges?.forEach(({ node: lineItem }) => {
    const seekaProduct = lineItemToSeekaProduct(order, lineItem);

    const groupingKey = `${seekaProduct.variantIdentifier}-${
      seekaProduct.variantName
    }-${seekaProduct.unitPrice}-${lineItem.seatZone ?? "empty"}`;

    // Check if a group already exists for this key
    if (!groupedProducts[groupingKey]) {
      groupedProducts[groupingKey] = { ...seekaProduct };
      return;
    }

    // Accumulate quantity for matching groups
    groupedProducts[groupingKey].quantity += seekaProduct.quantity;
  });

  void converge?.track?.initiateCheckout({
    currencyCode,
    checkoutIdentifier: order?.id,
    products: Object.values(groupedProducts),
  });
}

export function seekaTrackRegistration(
  converge: ConvergeSdk | undefined,
  values: WaitListFormValues,
  orgCountryCode?: string,
  joinWaitlistResult?: JoinWaitlistMutation["joinWaitlist"]
): void {
  const phone =
    joinWaitlistResult?.user?.e164Number ??
    joinWaitlistResult?.user?.phoneNumber;

  const countryCode =
    joinWaitlistResult?.user?.billingAddress?.country ??
    joinWaitlistResult?.user?.internationalPhoneNumber?.country ??
    orgCountryCode;

  void converge?.identity?.mergeProfile({
    email: [values.email],
    ...(phone ? { phone: [phone] } : {}),
    firstName: [values.firstName],
    lastName: [values.lastName],
    ...(countryCode ? { address: [{ countryCode }] } : {}),
  });
  void converge?.track?.lead({
    sourceContentName: "Registration form",
  });
}

export function seekaTrackWaitlistSignup(
  converge: ConvergeSdk | undefined,
  event: { id: string; name: string },
  values: WaitListFormValues,
  ticketTypes: { id: string; name: string; quantity?: number }[],
  orgCountryCode: string,
  joinResaleWaitlistResult?: JoinResaleWaitlistMutation["joinResaleWaitlist"]
): void {
  const phone =
    joinResaleWaitlistResult?.e164Number ??
    joinResaleWaitlistResult?.user?.phoneNumber;
  const countryCode =
    joinResaleWaitlistResult?.user?.billingAddress?.country ??
    joinResaleWaitlistResult?.user?.internationalPhoneNumber?.country ??
    orgCountryCode;

  void converge?.identity?.mergeProfile({
    email: [values.email],
    phone: [phone],
    firstName: [values.firstName],
    lastName: [values.lastName],
    ...(countryCode ? { address: [{ countryCode }] } : {}),
  });
  for (const ticketType of ticketTypes) {
    void converge?.track?.addToWishlist({
      productIdentifier: event.id,
      productName: event.name,
      variantIdentifier: ticketType.id,
      variantName: ticketType.name,
      quantity: ticketType.quantity,
    });
  }
}

export function seekaTrackSuccessfullOrder(
  converge: ConvergeSdk | undefined,
  order: OrderQuery["order"]
): void {
  try {
    if (!converge || !order) return;

    void mergeProfileFromFromOrder(converge, order);

    // Only track completed orders
    if (
      ![OrderStatus.Completed, OrderStatus.Paid, OrderStatus.PartPaid].includes(
        order.status
      )
    ) {
      return;
    }

    // Only track event / membership orders
    if (!order || (!order?.event && !order?.membership)) {
      return;
    }

    // Seeka will de-duplicate these events for up to 12 hours, so we can safely track them multiple times within that window.
    // We don't want to fire this event after at most 12 hours. We use 90 minutes below.
    const orderDateValue: string | Date =
      order?.orderType === OrderType.PaymentPlan
        ? order?.createdAt
        : order?.orderConfirmationSentAt;

    if (!orderDateValue) return;

    const ageOfOrderInMinutes =
      (Date.now() - new Date(orderDateValue).getTime()) / 1000 / 60;

    if (ageOfOrderInMinutes < 90) {
      void converge?.track?.order(
        {
          products: order?.lineItems?.edges?.map(({ node: lineItem }) => {
            return lineItemToSeekaProduct(order, lineItem);
          }),
          orderIdentifier: order?.id,
          orderNumber: order?.orderNumber,
          customerIdentifier: order?.user?.id,
          totalRefunds: order?.refundedAmount,
          totalPrice: order?.total,
        },
        // Acts as a de-duping mechanism on seeka side if a user comes back to this page / refreshes etc
        { activityIdentifier: `order_${order?.id}` }
      );
    }
  } catch (error) {
    console.error("Error tracking order", error);
  }
}

export async function seekaTrackMembershipAddToCart(
  converge: ConvergeSdk | undefined,
  membership: MembershipQuery["membership"],
  seats: SeatsIOSeat[],
  getPrice: (seat: SeatsIOSeat) => [number, number]
): Promise<void> {
  // Group the seats by price, type and name
  const groupedSeats: {
    [key: string]: CommerceActivityTrackingEventProductMetadata;
  } = {};

  for (const seat of seats) {
    const price = getPrice(seat)?.[0];
    const ticketTypeId = seat.ticketType?.id;
    const ticketTypeName = seat.ticketType?.name;

    const groupKey = `${price}-${ticketTypeId}-${ticketTypeName}`;
    if (groupedSeats[groupKey]) {
      // Increment quantity if the group already exists
      groupedSeats[groupKey].quantity += 1;
    } else {
      // Create a new group if it doesn't exist
      groupedSeats[groupKey] = {
        productIdentifier: membership?.id,
        productName: membership?.name,
        unitPrice: price,
        variantIdentifier: ticketTypeId,
        variantName: ticketTypeName,
        quantity: 1,
      };
    }
  }

  for (const key of Object.keys(groupedSeats)) {
    await converge?.track?.addToCart(groupedSeats[key]);
  }
}

export async function mergeProfileFromFromOrder(
  converge: ConvergeSdk | undefined,
  order: OrderQuery["order"]
): Promise<void> {
  const identifiers: PersonIdentifiers = {
    firstName: [order?.buyerInformation?.firstName],
    lastName: [order?.buyerInformation?.lastName],
    email: [order?.buyerInformation?.email],
    phone: [
      order?.user?.e164Number ??
        order?.user?.phoneNumber ??
        order?.buyerInformation?.phoneNumber,
    ],
    address: [
      {
        postcode: order?.buyerInformation?.postalCode,
        countryCode: order?.buyerInformation?.country,
      },
    ],
  };

  await converge?.identity.mergeProfile(identifiers);
}
